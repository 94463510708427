@import 'src/assets/core/base.scss';

.landing-page {
	span {
		color: $brand-orange !important;
	}
	&_logo {
		width: 11.5rem;
		height: 4.75rem;
	}
	&_video {
		// width: auto;
		@include media-breakpoint-down(lg) {
			width: 540px;
		}
		@include media-breakpoint-down(md) {
			width: 400px;
			height: 300px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 2rem;
			width: 270px;
			height: 230px;
		}
		@include media-breakpoint-down(xs) {
			margin-top: 2rem;
			width: 270px;
			height: 200px;
		}
	}
	&-header {
		color: white !important;
	}
	&-firstblock {
		background: rgb(47, 48, 59);
		background: -moz-radial-gradient(circle, rgba(47, 47, 59, 1) 25%, rgba(29, 58, 73, 1) 70%);
		background: -webkit-radial-gradient(circle, rgba(47, 47, 59, 1) 25%, rgba(29, 58, 73, 1) 70%);
		background: radial-gradient(circle, rgba(47, 47, 59, 1) 25%, rgba(29, 58, 73, 1) 40%);
		filter: progid:DXImageTransform.Microsoft.gradient(
				startColorstr="#2f303b",
				endColorstr="#1d3a49",
				GradientType=1
			);
		background-position: -25vw center;
		height: fit-content;
		padding-bottom: 2rem;
		@include media-breakpoint-down(sm) {
			// height: 100vh;

			padding-bottom: 1rem;
		}
	}
	&-secondblock {
		background: rgb(250, 195, 182);
		background: -moz-linear-gradient(180deg, rgba(250, 195, 182, 1) 0%, rgba(255, 255, 255, 1) 100%);
		background: -webkit-linear-gradient(180deg, rgba(250, 195, 182, 1) 0%, rgba(255, 255, 255, 1) 100%);
		background: linear-gradient(180deg, rgba(250, 195, 182, 1) 0%, rgba(255, 255, 255, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(
				startColorstr="#fac3b6",
				endColorstr="#ffffff",
				GradientType=1
			);
		min-height: 480px;
		height: fit-content;
		padding-top: 2rem;
		@include media-breakpoint-down(sm) {
			// height: 100vh;
		}
		color: $brand-teal;
	}
	&-thirdblock {
		@extend .pt-3;
		background: rgb(255, 255, 255);
		height: fit-content;
		color: $brand-teal;
	}
	&-fourthblock {
		@extend .pt-3;
		background: rgb(250, 195, 182);
		background: -moz-linear-gradient(360deg, rgba(250, 195, 182, 1) 0%, rgba(255, 255, 255, 1) 100%);
		background: -webkit-linear-gradient(360deg, rgba(250, 195, 182, 1) 0%, rgba(255, 255, 255, 1) 100%);
		background: linear-gradient(360deg, rgba(250, 195, 182, 1) 0%, rgba(255, 255, 255, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(
				startColorstr="#fac3b6",
				endColorstr="#ffffff",
				GradientType=1
			);
		min-height: 480px;
		height: fit-content;
		color: $brand-teal;
	}
	&-footer {
		background: $brand-teal;
		color: #fff;
		.borderTop {
			border-top: solid 1px #8a8ba7;
		}
	}
	&--roundlabels {
		@extend .landing-page_pinkshadow;
		width: 5.5rem;
		height: 5.5rem;
		background: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.labelsmall {
			font-size: 10px !important;
		}
	}
	&_pinkshadow {
		box-shadow: 12px 13px 12px -3px rgba(244, 189, 175, 0.75);
		-webkit-box-shadow: 12px 13px 12px -3px rgba(244, 189, 175, 0.75);
		-moz-box-shadow: 12px 13px 12px -3px rgba(244, 189, 175, 0.75);
	}
	&--darkroundlabels {
		@extend .landing-page--roundlabels;
		color: #fff;
		background: rgb(0, 0, 0);
		background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(133, 136, 164, 1) 100%);
		background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(133, 136, 164, 1) 100%);
		background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(133, 136, 164, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(
				startColorstr="#000000",
				endColorstr="#8588a4",
				GradientType=1
			);
	}
	&-hero {
		&_button {
			font-size: 1.25rem !important;

			@include media-breakpoint-down(lg) {
				font-size: 14px !important;
				padding: 2rem;
			}
		}

		&_img {
			width: auto;
			text-align: center;
			margin-bottom: 1rem;
			@include media-breakpoint-down(lg) {
				height: 60vh;
			}
			@include media-breakpoint-down(md) {
				height: 50vh;
			}
			@include media-breakpoint-down(xs) {
				height: 40vh;
				margin-bottom: 0rem;
			}
		}
		&_imgmobile {
			width: 610px;
			@include media-breakpoint-down(lg) {
				width: 610px;
			}
			@include media-breakpoint-down(md) {
				width: 510px;
			}
			@include media-breakpoint-down(xs) {
				width: 300px !important;
			}
		}
		&_topdar {
			@extend .d-flex, .justify-content-center, .flex-column, .pt-2, .pt-md-5;
			order: -1;
			align-self: flex-start;
			@include media-breakpoint-down(sm) {
				order: 2;
			}
			&-h1 {
				padding: 0;
				font-size: 5rem;
				color: white !important;
				margin: 0rem;

				font-weight: 900;
				@include media-breakpoint-down(md) {
					font-size: 3rem !important;
				}
				@include media-breakpoint-down(xs) {
					font-size: 2rem !important;
				}
			}
			&--orange {
				@extend .landing-page-hero_topdar-h1;
				font-weight: 600;
				margin-top: -2rem;
				color: $brand-orange !important;
				font-size: 4.75rem;
				@include media-breakpoint-down(md) {
					margin-top: -1rem;
				}
				@include media-breakpoint-down(xs) {
					font-size: 2rem !important;
				}
			}
			&--detail {
				color: white !important;
				font-weight: 400 !important;
				width: 70%;
				align-self: left;
				@include media-breakpoint-down(md) {
					width: 90%;
					font-size: 12px !important;
				}
			}
		}
	}
	&--avatar {
		height: 194px !important;
		width: 200px !important;
		.MuiAvatar-img {
			object-fit: contain;
		}
	}
	&--scrolling {
		-webkit-overflow-scrolling: touch;
		&::-webkit-scrollbar {
			display: none;
		}
		overflow-x: auto;
		flex-wrap: nowrap;
		scroll-behavior: smooth;
	}
	&--review {
		min-height: 12rem;
		max-height: fit-content;
		width: 265px !important;
		-webkit-overflow-scrolling: touch;
		&::-webkit-scrollbar {
			display: none;
		}
		.avatar {
			width: 4rem;
			height: 4rem;
			border: 1px solid $brand-orange;
		}
		.image {
			height: 14vh;
		}
	}
	&--button {
		width: 40%;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			width: 25%;
		}
	}
}
