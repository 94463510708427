@import 'src/assets/core/base.scss';
.main-container {
	min-height: 100vh;
	&--teal {
		@extend .main-container;
	}

	// padding: 0px !important;
	// margin: 0px !important;
}

.teal {
	&-search {
		position: absolute;
		right: 55px;
		top: 10px;
		&_box {
			@extend .teal-search;
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		&_button {
			@extend .teal-search;
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
		&_panel {
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}
}

.leftTop {
	position: absolute !important;
	top: 10px;
	left: 10px;
}

.rightTop {
	position: absolute !important;
	top: 10px;
	right: 10px;
}

.small-darkbar {
	background-color: $brand-teal;
	min-height: 1.5rem;
	border: none;
	margin: 0;
	padding: 0;
	margin-top: -1px;
}
