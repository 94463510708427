@import 'src/assets/core/base.scss';

.input-style {
	width: 3rem !important;
	height: 3rem;
	margin: 0 1rem;
	font-size: 2.5rem;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	color: $brand-orange;
	justify-content: center !important;
}

.input-style3 {
	display: flex;
	justify-content: center;
}
