@import 'src/assets/core/base.scss';

.dashboard {
	&__banner {
		padding: 0;
	}
	&__box {
		max-height: 7.5rem;
		@include media-breakpoint-down(sm) {
			max-height: initial;
		}
	}
	&__slider {
		height: 35vh;
		width: 100%;

		background-size: cover !important;
		background-repeat: no-repeat;
		background-position: center bottom;

		@include media-breakpoint-up(md) {
			height: 50vh;
		}
	}

	&_buttons {
		display: flex;
		flex-direction: column;
		position: absolute;
		right: 19px;
		top: 20vh;
		&-big {
			width: 5.5rem !important;
			height: 5.5rem !important;
		}
		@include media-breakpoint-down(md) {
			top: 12vh;
		}
	}

	&_tealTop {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		background-color: $primary !important;
		@extend .p-3, .pt-4;
		color: $white !important;
	}

	&_card {
		@extend .m-2, .p-2;
		max-height: 7.5rem;
		&-media {
			// flex-grow: 2;
			width: 6rem;
		}
		&-content {
			flex-grow: 2;
			padding: 0.1rem .5rem .1rem .7rem !important;
			min-height: 5.5rem;
		}
	}
}

.pink-container {
	@include media-breakpoint-down(sm) {
		align-self: center;
	}
	&:first-child {
		@include media-breakpoint-up(md) {
			margin-right: 2rem;
		}

		@include media-breakpoint-up(lg) {
			margin-right: 3rem;
		}
	}
}

.smaller {
	@include media-breakpoint-down(xs) {
		font-size: 8px !important;
	}
}

.flex-center-end {
	@extend .d-flex, .justify-content-center, .align-items-end;
}
