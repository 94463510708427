@import 'src/assets/core/base.scss';

.pink-container {
	width: 40vw;
	border: 1px solid $brand-teal;
	@extend .p-2;
	color: $primary;
	// background-color: $brand-orange-light !important;
	// &:first-child {
	// 	@extend .mr-3;
	// }
	@include media-breakpoint-down(md) {
		width: 90vw;
		margin-top: 1.5rem;
	}
}
