$primary: '#1d3a49';
$primaryLight: '#8a8ba7';
$primaryDark: '#09142a';
$secondary: '#ec231d';
$secondaryLight: '#ec541d';
$inheritDefault1: '#fefefe';
$inheritDefault2: '#f8f9ff';
$link-color: '#070919';
$second: '#070919';
$indigo: '#7420ff';
$purple: '#793de6';
$pink: '#fc26a4';
$red: '#f83245';
$orange: '#f4772e';
$yellow: '#ffc926';
$green: '#1bc943';
$teal: '#18e1a5';
$cyan: '#27dcf3';
