@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;900&display=swap');
@import "functions";
@import "variables";

/* -------begin customization-------- */
$brand-orange: #ec411b;
$brand-orange-light: rgba(236, 65, 27, 0.08);
$brand-teal: #1d3a49;
$brand-teal-light: #3f5764;
/* simply assign the value */
// $body-bg: #eeeeee;

/* or, use an existing variable */
// $theme-colors: (primary: $purple);

@import "bootstrap";
@import "variables-mui";

.big-thumbnail {
	width: 4rem !important;
	height: 4rem !important;
}

.small-thumbnail {
	width: 3rem !important;
	height: 3rem !important;
}

.MuiAvatar-img {
	object-fit: contain !important;
}
