@import 'src/assets/core/base.scss';

.react-tel-input .form-control {
	background: #fafafa !important;
}

.special-label {
	color: #383838 !important;
	background: #fafafa !important;
	padding: 0;
	font-size: 11px !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Metroplis, Roboto, "Helvetica Neue", Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-weight: 400 !important;
	line-height: 1;
	left: 10px !important;
}

.endicon {
	position: absolute;
	top: 17px;
	right: 14px;
}

.react-tel-input .form-control {
	font-size: 16px;
	background: #ffffff;
	border: 1px solid #8a8ba791;
	border-radius: 8px !important;
}
.error {
	border: 1px solid #f83245 !important;
}

.error-field .special-label {
	color: #f83245 !important;
}

.error-msg {
	margin: 0;
	font-size: 12px;
	margin-top: 3px;
	text-align: left;
	color: #f83245;
	font-weight: 400;
	line-height: 1.66;
}
