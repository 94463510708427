@import 'src/assets/core/base.scss';
.orange-layout {
	// position: absolute !important;
	// top: 1vh;
	// left: 42vw;
	margin-left: -8px;
	width: 100% !important;
	height: 52px !important;
	@include media-breakpoint-down(sm) {
		width: 100% !important;
		height: 45px !important;
	}
}

.main-container--orange {
	.MuiContainer-root {
		padding: 0 !important;
		@include media-breakpoint-down(md) {
			padding: 0 !important;
		}
	}
}
