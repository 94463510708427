@import 'src/assets/core/base.scss';

.landing-page {
	span {
		color: $brand-orange !important;
	}

	&--avatar {
		height: 194px !important;
		width: 200px !important;
		.MuiAvatar-img {
			object-fit: contain;
		}
	}

	&--button {
		width: 40%;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			width: 25%;
		}
	}
}
