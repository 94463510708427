@import 'src/assets/core/base.scss';

.order {
	&_form {
		align-self: center;
		& .MuiTextField-root {
			width: 90%;
			margin: 1rem;
			// margin-left: 0;
			@include media-breakpoint-up(md) {
				// width: 25ch;
			}
			@include media-breakpoint-up(xl) {
				// width: 45ch;
			}
		}

		& .MuiGrid-item {
			padding: 0 !important;
		}

		&-box {
			border-color: #c0c0c0 !important;
			&-label {
				position: absolute;
				// left: 25px;
				padding: 0px 3px;
				top: -9px;
				left: 10px;
				background-color: #fafafa;
				display: inline !important;
				font-size: 10px !important;
			}

			&-photo {
				height: 58px !important;
				background-color: lightgray !important;
			}
		}
	}

	&_head {
		@extend .p-0, .m-0, .px-lg-0, .pt-0;
	}

	&_tabs {
		// background-color: $brand-teal;
		margin-top: -0.5rem;
		@extend .d-flex, .justify-content-center, .border-0, .mb-0, .pb-0, .flex-column;
		& .MuiTabs-root {
			justify-content: center;
			background-color: $brand-teal;
			@extend .border-0, .mb-0, .pb-0;
			flex-grow: 1;
		}

		& .MuiTabs-flexContainer {
			@extend .justify-content-center;
		}

		& .MuiTabs-scroller {
			@include media-breakpoint-up(md) {
				flex: none !important;
				display: flex !important;
			}
		}

		& .MuiTabs-fixed {
			width: auto !important;
			background-color: $brand-teal-light;
			color: white;
			border-radius: 10px;
			@include media-breakpoint-down(sm) {
				width: 100% !important;
				border-radius: 0 !important;
			}
		}

		& .Mui-selected {
			background-color: white;
			color: $brand-teal;
			height: 70%;
			padding: 3px 10px !important;
			@extend .m-2, .rounded;
		}

		& .MuiTabs-indicator {
			background-color: none !important;
			color: none !important;
		}

		& .MuiTab-root {
			min-height: 28px !important;
			font-size: 12px;
			@include media-breakpoint-up(md) {
				min-width: 100px;
			}
		}
	}

	&_detail {
		@extend .d-flex, .justify-content-center, .flex-column;
		height: 80%;
		&--weight {
			font-size: 30px !important;
			@include media-breakpoint-down(sm) {
				font-size: 20px !important;
			}
		}
		&-redbadge {
			position: relative;
			top: 14px;
			right: 10px;
		}
		&-darkbar {
			background-color: $brand-teal;
			min-height: 3.5rem;
			border: none;
			margin: 0;
			padding: 0;
			margin-top: -1px;
		}
		& .MuiPaper-root {
			margin-top: -1.95rem !important;
			width: 85%;
			@extend .px-4, .py-2, .align-self-center;
			@include media-breakpoint-up(lg) {
				width: 700px !important;
			}
		}

		&-img {
			@extend .big-thumbnail;
			@extend .border, .border-secondary;
		}

		&-bar {
			margin: 0px 8px;
			height: 3.85rem;
			flex-grow: 1;
		}

		&-row_right {
			@extend .p-2,
				.mt-2,
				.mr-2,
				.border-bottom,
				.text-right,
				.d-flex,
				.justify-content-start,
				.justify-content-md-end,
				.text-primary,
				.font-weight-bold;
		}

		&-row_left {
			@extend .p-2, .mt-2, .border-bottom, .text-right, .d-flex, .justify-content-md-start;
		}

		&-row {
			@extend .p-2, .mt-2, .border-bottom, .d-flex, .text-primary, .font-weight-bold;
			&--nobottom {
				@extend .p-2, .mt-2;
				// border-bottom: none !important;
			}
			// @include media-breakpoint-down(sm) {
			// 	flex-direction: row !important;
			// }
		}

		&-traveller {
			& .MuiPaper-root {
				margin-top: -1.95rem !important;
				width: 55%;
				// @extend .px-4, .py-2, .align-self-center;
				@include media-breakpoint-down(sm) {
					width: 85% !important;
				}

				@include media-breakpoint-up(lg) {
					width: 500px !important;
				}
			}
			& .paymentType-badge {
				position: absolute;
				// top: 8px;
				right: -22px;
				width: 49px;
				@include media-breakpoint-down(xs) {
					width: 34px;
				}
			}
		}

		&-list {
			& .MuiPaper-root {
				margin-top: .5rem !important;
				width: 95% !important;
				min-height: 8rem;
				@extend .p-0, .m-0, .align-self-sm-center;
				@include media-breakpoint-up(lg) {
					min-height: 12rem;
				}
			}
			& .MuiStepIcon-active {
				color: #8a8ba7 !important;
			}

			&_img {
				@extend .small-thumbnail;
			}
			&-link {
				text-decoration: none !important;
			}
		}

		&-view {
			& .MuiPaper-root {
				margin-top: .5rem !important;
				// width: 80% !important;
				min-width: 60% !important;

				@extend .p-0, .m-0, .align-self-sm-center;
				@include media-breakpoint-down(sm) {
					width: 85% !important;
				}

				@include media-breakpoint-up(lg) {
					width: 500px !important;
				}
			}

			&_img {
				@extend .small-thumbnail;
			}
		}
	}
	&_listing {
		@extend .d-flex, .justify-content-center, .flex-column;
		&-list {
			& .MuiPaper-root {
				margin-top: .5rem !important;
				width: 95% !important;
				min-height: 7.5rem;
				// @include media-breakpoint-up(lg) {
				// 	min-height: 9.5rem;
				// }
				@extend .p-0, .m-0, .align-self-sm-center;
			}

			&_img {
				@extend .small-thumbnail;
			}
		}
		&-bar {
			margin: 0px 8px;
			height: 3.85rem;
			flex-grow: 1;
		}
		&-link {
			text-decoration: none !important;
		}
		&-order {
			& .MuiPaper-root {
				margin-top: -1.95rem !important;
				width: 55%;
				// min-height:30% !important;
				// @extend .px-4, .py-2, .align-self-center;
				@include media-breakpoint-down(sm) {
					width: 85% !important;
				}

				@include media-breakpoint-up(lg) {
					width: 500px !important;
				}
			}
			&_emptybox {
				height: 50px;
			}
			& .paymentType-badge {
				position: absolute;
				// top: 8px;
				right: 0px;
				width: 49px;
				@include media-breakpoint-down(xs) {
					width: 34px;
				}
			}
		}

		&-unReadMessage {
			color: white;
		}
	}
	&_emptyReceiverbox {
		height: .5rem;
	}
	&_empty {
		@extend .d-flex, .align-items-center, .justify-content-center;
		min-height: 5rem;
	}
}
.order_form {
	.react-tel-input {
		margin-top: 7px;
		@include media-breakpoint-down(md) {
			margin-left: 13px !important;
			width: 90% !important;
		}

		.form-control {
			font-size: 16px;
			padding: 18.5px 14px 16px 58px;
			background: #ffffff;
			border: 1px solid #8a8ba791;
			border-radius: 8px !important;
			width: 100% !important;
		}
		.special-label {
			top: 0 !important;
		}
	}
	.error {
		border: 1px solid #f83245 !important;
	}

	.error-field .special-label {
		color: #f83245 !important;
	}

	.error-msg {
		margin: 0;
		font-size: 12px;
		margin-top: 3px;
		text-align: left;
		color: #f83245;
		font-weight: 400;
		line-height: 1.66;
	}
}
